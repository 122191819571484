<template>
  <div>
    <transactionsComponent />
  </div>
</template>

<script>
import transactionsComponent from '@/components/transactions/transactionsComponent.vue';

export default {
  name: 'Transactions',
  components: {
    transactionsComponent,
  },
};
</script>