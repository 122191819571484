<template>
  <div class="mb-2">
    <v-card class="my-2">
      <v-card-title class="py-0 pl-1">
        <v-row
          :class="[
            'align-center',
            'custom-v-row',
            $vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : ''
          ]"
        >
          <v-col class="custom-v-col">
            <v-col cols="auto">
              {{ $t("menu.transactions") }}
            </v-col>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <transactionsListTable />
  </div>
</template>

<script>
import transactionsListTable from "./transactionsListTable.vue";

export default {
  data: () => ({}),
  components: {
    transactionsListTable
  }
};
</script>

<style scoped>
.custom-v-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.custom-v-col {
  display: flex;
  align-items: center;
}
</style>
